export default {
	comments: {
		heading: 'Kommentarer',
		showMore: 'Vis flere kommentarer',
		commentsCount: 'Antall kommentarer',
		goToComments: 'Les kommentarer',
		addCommentButtonText: 'Legg til kommentar',
		comment: 'Kommentar',
		firstname: 'Fornavn',
		email: 'Epost',
		reply: 'Svar',
		sendComment: 'Send',
		emailNotificationText: 'Varsle meg på e-post ved svar fra andre brukere'
	}
};
