import { MountLoaded } from './utils/mount.jsx';
import i18n from './utils/i18n';
import translations from './utils/translations';
import { Component as Carousel } from './modules/carousel/index.jsx';
import { Component as Comments } from './modules/comments/index.jsx';
import { Component as Pagination } from './modules/pagination/index.jsx';

i18n.extend(translations);

MountLoaded('.carousel-react', Carousel, ['type', 'heading', 'className', 'childrenComponents', 'customSettings', 'startIndex']);
MountLoaded('.comments-react', Comments, ['contentKey', 'contentTitle', 'url', 'siteKey', 'endpoint']);
MountLoaded('.pagination-react', Pagination, ['baseUrl', 'className', 'currentPage', 'numPages']);
