import Polyglot from 'node-polyglot';

const polyglot = new Polyglot({});

const i18n = {
	extend: function extend(obj) {
		polyglot.extend(obj);
	}
};

export default i18n;

export function translate(key, obj) {
	return polyglot.t(key, obj);
}
